import styled from "styled-components";

export const StyledNews = styled.div`
  .blog-area {
    padding-top: 60px;
    /* padding-bottom: 60px; */
    background-color: var(--background-color2);
  }
  .blog-area .recent-post-wrap {
    margin-top: 51px;
  }
  .blog-area .recent-post-wrap .recent-item {
    margin-bottom: 60px;
  }
  .blog-area .recent-post-wrap .recent-item .recent__img {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
  }
  .blog-area .recent-post-wrap .recent-item .recent__img .meta__date-date {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    text-transform: uppercase;
    background: var(--teal);
    color: var(--white);
    font-weight: 800;
    letter-spacing: 1px;
    padding: 0 25px 0 30px;
    line-height: var(--triple-line-height);
    z-index: 1;
    display: inline-block;
  }
  .blog-area
    .recent-post-wrap
    .recent-item
    .recent__img
    .meta__date-date:after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: var(--teal);
    -webkit-transform-origin: bottom left;
    transform-origin: bottom left;
    -webkit-transform: skew(-12deg, 0deg);
    -moz-transform: skew(-12deg, 0deg);
    -ms-transform: skew(-12deg, 0deg);
    -o-transform: skew(-12deg, 0deg);
    transform: skew(-12deg, 0deg);
  }
  .blog-area .recent-post-wrap .recent-item .recent__img .meta__date-date2,
  .blog-area
    .recent-post-wrap
    .recent-item
    .recent__img
    .meta__date-date2:after {
    background-color: var(--highlight-color2);
  }
  .blog-area .recent-post-wrap .recent-item .recent__img .meta__date-date3,
  .blog-area
    .recent-post-wrap
    .recent-item
    .recent__img
    .meta__date-date3:after {
    background-color: var(--highlight-color);
  }
  .blog-area .recent-post-wrap .recent-item .recent__img .meta__date-date4,
  .blog-area
    .recent-post-wrap
    .recent-item
    .recent__img
    .meta__date-date4:after {
    background-color: var(--highlight-color3);
  }

  .blog-area .recent-post-wrap .recent-item .recent__img img {
    width: 100%;
  }
  .blog-area
    .recent-post-wrap
    .recent-item
    .news__content
    .news__content-title {
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 9px;
  }
  .blog-area
    .recent-post-wrap
    .recent-item
    .news__content
    .news__content-title
    a {
    color: var(--dark-text);
    -webkit-transition: 0.3s ease-in;
    -moz-transition: 0.3s ease-in;
    -ms-transition: 0.3s ease-in;
    -o-transition: 0.3s ease-in;
    transition: 0.3s ease-in;
  }
  .blog-area
    .recent-post-wrap
    .recent-item
    .news__content
    .news__content-title
    a:hover {
    color: var(--highlight-color);
  }
  .blog-area .recent-post-wrap .recent-item .news__content .news__content-list {
    margin-bottom: 30px;
  }
  .blog-area
    .recent-post-wrap
    .recent-item
    .news__content
    .news__content-list
    li {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding: 0 10px;
  }
  .blog-area
    .recent-post-wrap
    .recent-item
    .news__content
    .news__content-list
    li:first-child {
    padding-left: 0;
  }
  .blog-area
    .recent-post-wrap
    .recent-item
    .news__content
    .news__content-list
    li:last-child {
    padding-right: 0;
  }
  .blog-area
    .recent-post-wrap
    .recent-item
    .news__content
    .news__content-list
    li.news__content-active__dot:after {
    position: absolute;
    content: ".";
    top: -2px;
    right: -5px;
    font-size: var(--base-font-size);
    color: var(--highlight-color2);
  }
  .blog-area
    .recent-post-wrap
    .recent-item
    .news__content
    .news__content-list
    li
    a {
    color: var(--highlight-color2);
  }
  .blog-area .recent-post-wrap .recent-item .news__content .news__content-text {
    line-height: var(--base-line-height);
    margin-bottom: 32px;
  }
  /*=== recent-post-wrap ===*/
  .blog-area .recent-post-wrap .third-recent-box {
    background-color: var(--background-color2);
    padding: 60px 60px 60px 60px;
  }
  .blog-area .recent-post-wrap .third-recent-box .third-recent-item li {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin-bottom: 60px;
  }
  .blog-area
    .recent-post-wrap
    .third-recent-box
    .third-recent-item
    li:last-child {
    margin-bottom: 0;
  }
  .blog-area
    .recent-post-wrap
    .third-recent-box
    .third-recent-item
    li
    .recent__img {
    margin-right: 30px;
  }
  .blog-area
    .recent-post-wrap
    .third-recent-box
    .third-recent-item
    li
    .recent__content {
    margin-top: -9px;
  }
  .blog-area
    .recent-post-wrap
    .third-recent-box
    .third-recent-item
    li
    .recent__content
    span {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--highlight-color2);
  }
  .blog-area
    .recent-post-wrap
    .third-recent-box
    .third-recent-item
    li
    .recent__content
    h4 {
    font-size: 22px;
    font-weight: 800;
    line-height: var(--base-line-height);
    margin-top: 10px;
  }
  .blog-area
    .recent-post-wrap
    .third-recent-box
    .third-recent-item
    li
    .recent__content
    h4
    a {
    color: var(--dark-text);
    -webkit-transition: 0.3s ease-in;
    -moz-transition: 0.3s ease-in;
    -ms-transition: 0.3s ease-in;
    -o-transition: 0.3s ease-in;
    transition: 0.3s ease-in;
  }
  .blog-area
    .recent-post-wrap
    .third-recent-box
    .third-recent-item
    li
    .recent__content
    h4
    a:hover {
    color: var(--highlight-color2);
  }
`;

import React from "react";
import { StyledNews } from "./NewsStyled";
import { IoNewspaperSharp } from "react-icons/io5";

const news_items = [
  {
    date: "5 august, 2022",
    title: `Press release - Hungary's racist autocrat is a threat to American democracy`,
    author: "Action for Democracy",
    excerpt: `The evil of fascism is on the rise again, and not just in Russia.`,
    link: "https://actionfordemocracy.org/newsitem3",
    source: "Action for Democracy",
  },
  {
    date: "30 july, 2022",
    title: `Hungary's racist strongman, and the Americans who love him`,
    author: "The Editorial Board",
    excerpt: `Hungarian Prime Minister Viktor Orban, an autocrat astride a country whose GDP is more meager than that of Kansas and whose population is smaller than Michigan's, has become a role model for America's right-wing populists who admire his blueprint for dismantling democracy.`,
    link: "https://www.washingtonpost.com/opinions/2022/07/30/hungarian-prime-minister-viktor-orban-cpac-democracy/",
    source: "The Washington Post",
  },
  {
    date: "4 july, 2022",
    title: `Does Hungary Offer a Glimpse of Our Authoritarian Future?`,
    author: "Andrew Marantz",
    excerpt: `American conservatives recently hosted their flagship conference in Hungary, a country that experts call an autocracy. Its leader, Viktor Orbán, provides a potential model of what a Trump after Trump might look like.`,
    link: "https://www.newyorker.com/magazine/2022/07/04/does-hungary-offer-a-glimpse-of-our-authoritarian-future",
    source: "The New Yorker",
  },
  {
    date: "1 june, 2022",
    title: `STRENGTHENING DEMOCRACY IN THE DIGITAL AGE`,
    author: "Barack Obama",
    excerpt: `If we want democracy to flourish, we will have to fight for it, we will have to nurture it, we will have to demonstrate its value, again and again, in improving the lives of ordinary people.`,
    link: "https://www.obama.org/copenhagen-2022/",
    source: "Obama.org",
  },
  {
    date: "18 may, 2022",
    title: `The Orbanization of America: How to capture a democracy`,
    author: "Ishaan Tharoor",
    excerpt: `That's a situation toward which many experts fear the United States is sleepwalking.`,
    link: "https://www.washingtonpost.com/world/2022/05/18/orban-democracy-trump-united-states-elections-hungary/",
    source: "The Washington Post",
  },
  {
    date: "18 may, 2022",
    title: `The Orbanization of America: Florida shadows Hungary's war on LGBTQ rights`,
    author: "Ishaan Tharoor",
    excerpt: `Maybe Florida is becoming our American Hungary.`,
    link: "https://www.washingtonpost.com/world/2022/05/18/cpac-hungary-lgbtq-orban-florida-desantis/",
    source: "The Washington Post",
  },
  {
    date: "17 may, 2022",
    title: `The Orbanization of America: The U.S. right walks in Hungary's path`,
    author: "Ishaan Tharoor",
    excerpt: `In the summer of 2018, Hungarian Prime Minister Viktor Orban declared war on a generation.`,
    link: "https://www.washingtonpost.com/world/2022/05/17/viktor-orban-american-right-illiberal-orbanization/",
    source: "The Washington Post",
  },
  {
    date: "1 may, 2022",
    title: `Global State of Democracy Report 2021`,
    author: "IDEA",
    excerpt: `The year 2020 has seen the world besieged by a pandemic that has claimed millions of lives. The stability that most of the world enjoyed after the Cold War has perhaps been permanently disrupted, and all nations are struggling to adjust to these abrupt changes.`,
    link: "https://www.idea.int/gsod/global-report",
    source: "International Institute for Democracy and Electoral Assistance",
  },
  {
    date: "21 april, 2022",
    title: `Regulation has to be part of the answer to combating online disinformation`,
    author: "Melissa de Witte",
    excerpt: `Former U.S. President Barack Obama delivered a keynote address about how information is created and consumed, and the threat that disinformation poses to democracy.`,
    link: "https://news.stanford.edu/2022/04/21/disinformation-weakening-democracy-barack-obama-said/",
    source: "Stanford.edu",
  },
  {
    date: "12 april, 2022",
    title: `We thank your support for building a genuine and free democratic future`,
    author: "Action for Democracy",
    excerpt: `In the wake of the Hungarian elections, despite a heavy heart we feel pride and gratitude for what we have built in Action for Democracy. We thank our supporters for making it possible.`,
    link: "https://actionfordemocracy.org/newsitem2",
    source: "Action for Democracy",
  },
  {
    date: "4 april, 2022",
    title: `Orbán's victory in Hungary adds to the darkness engulfing Europe`,
    author: "Timothy Garton Ash",
    excerpt: `The pro-Putin nationalist managed to turn the war in Ukraine to his advantage in a win that deepens the EU's troubles.`,
    link: "https://www.theguardian.com/commentisfree/2022/apr/04/orbans-victory-in-hungary-adds-to-the-darkness-overshadowing-europe",
    source: "The Guardian",
  },
  {
    date: "27 march, 2022",
    title: `Action for Democracy has decided to support three Hungarian civic initiatives`,
    author: "Action for Democracy",
    excerpt: `The Print-It-Yourself! movement, local government action to help refugees in Ukraine, and the restoration of democratic values and a system of checks and balances - these are the three areas that Action for Democracy has decided to support.`,
    link: "https://actionfordemocracy.org/newsitem",
    source: "Action for Democracy",
  },
  {
    date: "23 march, 2022",
    title: `Viktor Orban reaps benefits of tight grip on Hungary's media ahead of election`,
    author: "Marton Dunai",
    excerpt: `Orban, a self-styled "illiberal democrat", has used a decade in power to tighten his grip on Hungary's media and squeeze out alternative viewpoints. It has become one of his most significant advantages as he prepares for a general election on April 3.`,
    link: "https://www.ft.com/content/a6beb54a-a3f2-4a2c-b5c4-438139592edc",
    source: "Financial Times",
  },
  {
    date: "22 march, 2022",
    title: `The E.U. Is United Against Russia—With One Notable Exception`,
    author: "Amanda Coakley",
    excerpt: `In the infamous Transylvania speech announcing Hungary in 2014 as an “illiberal state,” Orban also called Russia—along with China, Singapore, India, and Turkey—a model for Hungarian society.`,
    link: "https://time.com/6159342/european-union-russia-hungary/",
    source: "Time",
  },
  {
    date: "15 march, 2022",
    title: `Myths that blinded the West to Putin's Plans`,
    author: "Timothy Snyder",
    excerpt: `Americans and Europeans were guided through the new century by a tale about 'the end of history,' by what I will call the politics of inevitability, a sense that the future is just more of the present, that the laws of progress are known, that there are no alternatives, and therefore nothing really to be done.`,
    link: "https://open.spotify.com/episode/1bcJtwQKhkDJCxfrjDNJcj?si=iUMuzjiFSsSUxWdj5gO3LA&nd=1",
    source: "The Ezra Klein Show",
  },
  {
    date: "14 march, 2022",
    title: `Could Putin lose? Here's why the 'End of History' author is optimistic`,
    author: "Greg Sargent",
    excerpt: `Francis Fukuyama, the political theorist who developed the famous "end of history" thesis, is generating some buzz with a new piece that makes a stark prediction: "Russia is heading for an outright defeat in Ukraine."`,
    link: "https://www.washingtonpost.com/opinions/2022/03/14/putin-could-lose-ukraine-fukuyama-optimistic/",
    source: "The Washington Post",
  },
  {
    date: "10 march, 2022",
    title: `Preparing for Defeat`,
    author: "Francis Fukuyama",
    excerpt: `Russia is heading for an outright defeat in Ukraine. Russian planning was incompetent, based on a flawed assumption that Ukrainians were favorable to Russia and that their military would collapse immediately following an invasion. Russian soldiers were evidently carrying dress uniforms for their victory parade in Kyiv rather than extra ammo and rations. `,
    link: "https://www.americanpurpose.com/articles/preparing-for-defeat/",
    source: "American Purpose",
  },
  {
    date: "7 march, 2022",
    title: `"Hungarian Spring" campaign urges Hungarians abroad to vote in Apr. 3 election`,
    author: "Steven Nelson",
    excerpt: `Hungarians who have left the country to work or study often find voting from abroad a challenge due to the difficulties imposed by the government. But Hungarian Spring is hoping to persuade Hungarians residing in the US, Western Europe, and other parts of the Western world of the value of registering and voting in the county’s parliamentary elections on April 3.`,
    link: "https://hungarianpolitics.com/2022/03/07/hungarian-spring-campaign-urges-hungarians-abroad-to-vote-in-apr-3-election/",
    source: "HungarianPolitics.com",
  },
  {
    date: "5 march, 2022",
    title:
      "It means the end of Putin: Former NATO commander Wesley Clark explains Ukraine war",
    author: "Gen. Wesley Clark",
    excerpt: `The most important move now is for the president to announce Vladimir Putin is a war criminal. This gives enormous diplomatic leverage and a greater incentive to help the campaign.`,
    link: "https://nypost.com/2022/03/02/former-nato-commander-wesley-clark-urges-us-to-reconsider-no-fly-zone/",
    source: "NY Post",
  },
  {
    date: "4 march, 2022",
    title: "Putin's war on the liberal order",
    author: "Francis Fukuyama",
    excerpt: `Democratic values were already under threat around the world before the Russian invasion of Ukraine. Now we need to rekindle the spirit of 1989.`,
    link: "https://www.ft.com/content/d0331b51-5d0e-4132-9f97-c3f41c7d75b3",
    source: "Financial Times",
  },
  {
    date: "28 feb, 2022",
    title: "Why Vladimir Putin has already lost this war",
    author: "Yuval Noah Harari",
    excerpt: `The Russians may yet conquer Ukraine. But Ukrainians have shown in the past few days that they will not let them hold it.`,
    link: "https://www.theguardian.com/commentisfree/2022/feb/28/vladimir-putin-war-russia-ukraine",
    source: "the Guardian",
  },
  {
    date: "25 feb, 2022",
    title:
      "How to counter Putin: NATO's former supreme allied commander for Europe weighs in",
    author: "Gen. Wesley Clark",
    excerpt: `Russia has taken off its mask. For years, President Vladimir Putin and his oligarchs have enjoyed the benefits of Western democracies, their wealth and their lifestyles, all the while trying to undercut and destroy them.`,
    link: "https://www.nydailynews.com/opinion/ny-oped-who-putin-really-is-20220225-6gcvrti6uzf4ngttpz33x4i2ay-story.html",
    source: "NY Daily News",
  },
  {
    date: "24 feb, 2022",
    title:
      "Exclusive: New democracy group targets Hungary as 'battleground state'",
    author: "Zachary Basu",
    excerpt: `A pro-democracy group advised by top historians, diplomats, journalists and a former NATO supreme allied commander launched Thursday with an initial focus on Hungary, casting it as the "next battleground state in the global fight to defend democracy."`,
    link: "https://www.axios.com/hungary-elections-democracy-trump-orban-b858c73e-e51b-417d-9062-bf6faf186c6a.html",
    source: "Axios",
  },
  {
    date: "24 feb, 2022",
    title:
      "Putin's denial of Ukrainian statehood carries dark historical echoes",
    author: "Timothy Snyder",
    excerpt: `There is a deeper issue here. Whatever language we speak, it is what we say that matters. Our identity is not to be decided by distant tyrants, whatever language they might speak.`,
    link: "https://amp.ft.com/content/1df8a99b-804c-467b-98e1-ce8b47b5b513",
    source: "Financial Times",
  },
  {
    date: "23 feb, 2022",
    title: "Opinion | Putin Is Making a Historic Mistake",
    author: "Madeleine Albright",
    excerpt: `Ukraine is entitled to its sovereignty, no matter who its neighbors happen to be. In the modern era, great countries accept that, and so must Mr. Putin. That is the message undergirding recent Western diplomacy. It defines the difference between a world governed by the rule of law and one answerable to no rules at all."`,
    link: "https://www.nytimes.com/2022/02/23/opinion/putin-ukraine.html?referringSource=articleShare",
    source: "New York Times",
  },
  {
    date: "18 feb, 2022",
    title: "Will Russia Invade? It Did in Hungary in 1956",
    author: "Charles Gati",
    excerpt: `Somewhat analogous to Russia’s claim that its forces have begun to pull back from the Ukrainian border is what happened in Hungary in late 1956.`,
    link: "https://www.nytimes.com/2022/02/18/opinion/letters/donald-trump-wealth-jan-6.html?smid=fb-share&fbclid=IwAR06V0Y9MFWPguW5CT-PIP95godFu3IX-PY1gRSYy-25Huy9F-GfqCqWhxI#link-1bd23134",
    source: "New York Times",
  },
  {
    date: "16 feb, 2022",
    title: "Hungary's Freedom Election",
    author: "Kati Marton",
    excerpt: `Over the past 12 years, Prime Minister Viktor Orbán has turned Hungary into a corruption-ridden "illiberal democracy" that is at odds with the rest of Europe. But now that the usually fractious opposition is united behind one candidate, Hungarians finally have a chance to show Orbán the door.`,
    link: "https://www.project-syndicate.org/commentary/hungary-election-2022-democracy-on-the-ballot-by-kati-marton-2022-02",
    source: "Project Syndicate",
  },
  {
    date: "11 feb, 2022",
    title: "We Underestimated Putin Once. We Can’t Make That Mistake Again.",
    author: "Evelyn N. Farkas",
    excerpt: `Mr. Putin desires to be respected on the world stage. Further exposing Russia’s global meddling — including its support to authoritarian regimes in Eastern Europe, Latin America and Africa — will put Russia on the defensive. It also could prompt leaders accepting Russian support to pull back, fearful of public backlash jeopardizing their own holds on power.`,
    link: "https://www.nytimes.com/2022/02/11/opinion/putin-russia-biden-ukraine.html",
    source: "New York Times",
  },
];

const RecentItem = ({ date, image, title, author, excerpt, link, source }) => (
  <div className="col-lg-6 col-sm-12">
    <div className="recent-item">
      <div className="news__content">
        <h3 className="news__content-title">
          <a href={link} alt={title} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        </h3>

        <ul className="news__content-list">
          <li className="news__content-active__dot">
            <span className="meta__date-date">{author}</span>
          </li>
          <li className="news__content-active__dot">
            <span className="meta__date-date">{date}</span>
          </li>
          <li className="news__content-active__dot">
            <a href={link} alt={title}>
              <span className="meta__date-date">{source}</span>
            </a>
          </li>
        </ul>
        <p className="news__content-text">{excerpt}</p>
        <a
          href={link}
          alt={title}
          target="_blank"
          rel="noopener noreferrer"
          className="theme-btn"
        >
          read more
        </a>
      </div>
      {image && (
        <div className="recent__img">
          <span className="meta__date-date">{date}</span>
          <img src={image} alt="service-post" />
        </div>
      )}
    </div>
  </div>
);

const News = () => {
  return (
    <StyledNews>
      <section className="blog-area blog-area2">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="section-heading text-center">
                <div className="section-icon">
                  <IoNewspaperSharp />
                </div>
                <h2 className="section__title">Our News</h2>
              </div>
            </div>
          </div>
          <div className="row recent-post-wrap">
            {news_items.map((n) => (
              <RecentItem
                date={n.date}
                title={n.title}
                author={n.author}
                excerpt={n.excerpt}
                link={n.link}
                source={n.source}
              />
            ))}
          </div>
        </div>
      </section>
    </StyledNews>
  );
};

export default News;
